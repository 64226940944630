import classnames from "classnames";
import { isEmpty, noop } from "lodash";
import React from "react";
import { Link } from "../../utils/location";
import Image from "../image";
import * as styles from "./index.module.scss";

const List = ({ children, menu, style, tight, classNames, rtl }) => (
  <div
    className={classnames(
      styles.List,
      menu && styles.Menu,
      tight && styles.Tight,
      rtl && styles.RTL,
      classNames,
    )}
    style={style}
  >
    {children}
  </div>
);

List.Item = ({
  children,
  style,
  hasErrors,
  to,
  replace,
  onClick,
  onMouseOver = noop,
  centered,
  tight,
  bottomBorder,
  noBorder,
  rtl,
  refEl,
  classNames,
}) =>
  to ? (
    <Link
      ref={refEl}
      to={to}
      onMouseOver={onMouseOver}
      onClick={onClick}
      replace={replace}
      style={style}
      className={classnames(
        styles.ListItem,
        tight && styles.Tight,
        bottomBorder && styles.BottomBorder,
        hasErrors && styles.HasErrors,
        centered && styles.Centered,
        noBorder && styles.NoBorder,
        rtl && styles.RTL,
        classNames,
      )}
    >
      {children}
    </Link>
  ) : (
    <div
      ref={refEl}
      onClick={onClick}
      style={style}
      onMouseOver={onMouseOver}
      className={classnames(
        styles.ListItem,
        tight && styles.Tight,
        bottomBorder && styles.BottomBorder,
        hasErrors && styles.HasErrors,
        centered && styles.Centered,
        noBorder && styles.NoBorder,
        classNames,
      )}
    >
      {children}
    </div>
  );

List.Card = ({
  children,
  style,
  hasErrors,
  to,
  replace,
  onClick,
  centered,
  appStyles = {},
  classNames,
  refEl,
  animateCard,
  target
}) => {
  const { Card = {} } = appStyles;
  return to ? (
    <Link
      to={to}
      onClick={onClick}
      replace={replace}
      style={{ ...Card, ...style }}
      className={classnames(
        styles.ListCard,
        hasErrors && styles.HasErrors,
        centered && styles.Centered,
        animateCard && styles.AnimateCard,
        classNames,
      )}
      ref={refEl}
      target={target}
    >
      {children}
    </Link>
  ) : (
    <div
      onClick={onClick}
      style={{ ...Card, ...style }}
      className={classnames(
        styles.ListCard,
        hasErrors && styles.HasErrors,
        centered && styles.Centered,
        animateCard && styles.AnimateCard,
        classNames,
      )}
      ref={refEl}
    >
      {children}
    </div>
  );
};

List.Card.Image = ({
  children,
  style,
  onClick,
  height,
  width,
  background,
  classNames,
}) => (
  <div
    onClick={onClick}
    style={{
      ...style,
      height,
      width,
      background,
    }}
    className={classnames(styles.ListCardImage, classNames)}
  >
    {children}
  </div>
);

List.Item.Content = ({ children, style }) => (
  <div style={style} className={styles.ListItemContent}>
    {children}
  </div>
);

List.Card.Content = ({ children, style, tight }) => (
  <div
    style={style}
    className={classnames(styles.ListCardContent, tight && styles.Tight)}
  >
    {children}
  </div>
);

List.Card.Description = ({ children, style }) => (
  <div style={style} className={styles.ListCardDescription}>
    {children}
  </div>
);

List.Card.Description.ExtraInfo = ({ children, style }) => (
  <div style={style} className={styles.ListCardDescriptionExtraInfo}>
    {children}
  </div>
);

List.Separator = () => <div className={styles.ListSeparator} />;

List.Item.Title = ({ children, style, light }) => (
  <div style={style} className={classnames(styles.ListItemTitle, styles.Light)}>
    {children}
  </div>
);

List.Card.Title = ({ children, style, appStyles = {} }) => {
  const { CardTitle = {} } = appStyles;
  return (
    <div style={{ ...CardTitle, ...style }} className={styles.ListCardTitle}>
      {children}
    </div>
  );
};

List.Item.Actions = ({ children, style, color, rtl }) => (
  <div
    style={{ ...style, color }}
    className={classnames(styles.ListItemActions, rtl && styles.RTL)}
  >
    {children}
  </div>
);

List.Card.Actions = ({ children, style, color, classNames }) => (
  <div
    style={{ ...style, color }}
    className={classnames(styles.ListCardActions, classNames)}
  >
    {children}
  </div>
);

List.Item.ErrorPanel = ({ children, style, rtl, appStyles = {} }) => (
  <div
    style={{
      ...style,
      ...appStyles.ErrorBox,
      ...(appStyles.cardBorderRadius && {
        borderRadius: appStyles.cardBorderRadius,
      }),
    }}
    className={classnames(styles.ListItemErrorPanel, rtl && styles.RTL)}
  >
    {children}
  </div>
);

List.Item.WarningPanel = ({ children, style, rtl, appStyles = {} }) => (
  <div
    style={{
      ...style,
      ...appStyles.WarningBox,
      ...(appStyles.cardBorderRadius && {
        borderRadius: appStyles.cardBorderRadius,
      }),
    }}
    className={classnames(
      styles.ListItemErrorPanel,
      styles.ListItemWarningPanel,
      rtl && styles.RTL,
    )}
  >
    {children}
  </div>
);

List.Card.ErrorPanel = ({ children, style, rtl, appStyles = {} }) => (
  <div
    style={{
      ...style,
      ...appStyles.ErrorBox,
      ...(appStyles.cardBorderRadius && {
        borderRadius: appStyles.cardBorderRadius,
      }),
    }}
    className={classnames(styles.ListItemErrorPanel, rtl && styles.RTL)}
  >
    {children}
  </div>
);

List.Card.WarningPanel = ({ children, style, rtl, appStyles = {} }) => (
  <div
    style={{
      ...style,
      ...appStyles.WarningBox,
      ...(appStyles.cardBorderRadius && {
        borderRadius: appStyles.cardBorderRadius,
      }),
    }}
    className={classnames(
      styles.ListItemErrorPanel,
      styles.ListItemWarningPanel,
      rtl && styles.RTL,
    )}
  >
    {children}
  </div>
);

List.Card.SuccessPanel = ({ children, style, rtl, appStyles = {} }) => (
  <div
    style={{
      ...style,
      ...(appStyles.cardBorderRadius && {
        borderRadius: appStyles.cardBorderRadius,
      }),
    }}
    className={classnames(styles.ListItemSuccessPanel, rtl && styles.RTL)}
  >
    {children}
  </div>
);

List.Item.SuccessPanel = ({ children, style, rtl, appStyles = {} }) => (
  <div
    style={{
      ...style,
      ...(appStyles.cardBorderRadius && {
        borderRadius: appStyles.cardBorderRadius,
      }),
    }}
    className={classnames(styles.ListItemSuccessPanel, rtl && styles.RTL)}
  >
    {children}
  </div>
);

List.Item.Image = ({
  mediaType,
  mediaKey,
  sizes,
  imagePreview,
  noImageRenderer: NoImageRenderer,
  style: wrapperStyle,
  imgStyle,
  classNames,
  width,
  height,
  background,
}) => {
  const noImage = isEmpty(mediaKey);

  return (
    <div
      className={classnames(styles.ListImage, classNames)}
      style={{
        ...wrapperStyle,
        background: noImage ? "transparent" : background,
        width,
        height,
      }}
    >
      {noImage ? (
        <NoImageRenderer />
      ) : (
        <Image
          imgStyle={imgStyle}
          mediaKey={mediaKey}
          mediaType={mediaType}
          sizes={sizes}
          imagePreview={imagePreview}
          style={{ width: "100%", position: "static" }}
        />
      )}
    </div>
  );
};

export default List;
